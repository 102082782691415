const services = [
  {
    id: 1,
    title: "Сборочный участок",
    text: (
      <>
        <h5>Сборочный участок имеет:</h5>
        <p>- антистатическое покрытие полов;</p>
        <p>- система климат-контроля помещения;</p>
        <p>- беспылевые рабочие места;</p>
        <p>
          - климатическая камера тепла и холода (габариты 2300х2000х1900 мм) для
          испытаний и тестирования узлов/приборов
        </p>
        <h5>Завод может:</h5>
        <p>- слесарно-сборочные работы любой сложности;</p>
        <p>
          - выполнять сборку, юстировку, тестирование, обеспечивая при этом
          высокую точность изготовления следующих приборов: прецизионных,
          оптико-механических, оптико-электронных, лазерных;
        </p>
        <p>- выполнять сборку, юстировку и тестирование отдельных узлов;</p>
        <p>
          - выполнять климатические испытания и тестирование узлов/приборов с
          диапазоном стабилизируемых температур от -40°С до +85°С с точность
          поддержания температуры ±1°С
        </p>
        <p>- порошковая покраска деталей ( габариты: 900 х 900мм )</p>
        <p>- покраска эмалью</p>
        <p>
          - сборка ГЛОНАСС-GPS приемников и тахеометров из импортных
          комплектующих
        </p>
        <h5>Контакты для оказания услуг:</h5>
        <p>- oaoeomz@gmail.com</p>
        <p>- +7 (495) 911-01-12</p>
        <p>- +7 (495) 911-02-75</p>
        <p>- +7 (495) 911-32-70</p>
      </>
    ),
  },
  {
    id: 2,
    title: "Механический участок",
    text: (
      <>
        <h5>Механический участок:</h5>
        <p>
          Акционерное Общество "Экспериментальный оптико-механический завод" (
          АО "ЭОМЗ" ) существует более 80 лет и имеет оборудование, на котором
          возможно выполнять работы высокой точности: токарные станки (ЧПУ),
          фрезерные станки (ЧПУ), высокточный шлифовальный станок для круглого
          шлифования, резьбошлифовальное оборудование. Данное оборудование
          позволяет выполнять работы по 4-5 квалитетам точности для всех
          основных типов посадок для размеров 0-150 мм.
        </p>
        <h5>Завод может выполнить работы:</h5>
        <p>- Фрезерные</p>
        <p>- Токарные</p>
        <p>- Слесарные</p>
        <p>- Шлифовка круглая</p>
        <p>- порошковая покраска деталей ( габариты: 900 х 900мм )</p>
        <p>- покраска эмалью</p>
        <p>- Резьбошлифовка</p>
        <p>
          На заводе имеется достаточная база контрольно-измерительных
          инструментов.
        </p>
        <h5>Контакты для оказания услуг:</h5>
        <p>- info@eomz.ru</p>
        <p>- +7 (495) 911-01-12</p>
        <p>- +7 (495) 911-02-75</p>
        <p>- +7 (495) 911-32-70</p>
      </>
    ),
  },
]

export default services
