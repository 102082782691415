import { NavLink } from "react-router-dom"
import history from "../Images/About/history.jpg"
import service from "../Images/About/service.jpg"
import documents from "../Images/About/documents.jpg"
import certificats from "../Images/About/certificats.jpg"
import jobs from "../Images/About/jobs.jpg"
import goods from "../Images/About/purchases.jpg"
import doc from "../Images/About/doc.png"
import cert1 from "../Images/About/certificat1.png"
import cert2 from "../Images/About/certificat2.png"
import cert3 from "../Images/About/certificat3.png"
import doc1 from "../Components/Docs/Устав АО Роскартография.pdf"
import doc2 from "../Components/Docs/ПОЛОЖЕНИЕ о филиале ЭОМЗ.pdf"
import doc3 from "../Components/Docs/1_ПОЛОЖЕНИЕ_О_ЗАКУПКЕ.docx"
import doc4 from "../Components/Docs/2. Приложение № 1 Положение о закупочной комиссии.docx"
import doc5 from "../Components/Docs/3. Приложение № 2 Положение о планировании.docx"
import doc6 from "../Components/Docs/3.1. Приложение № 1_2021.xlsx"
import doc7 from "../Components/Docs/4. Приложение № 3 Порядок проведения торгов.docx"
import doc8 from "../Components/Docs/5. Приложение № 4 Особенности выбора нескольких победителей.docx"
import doc9 from "../Components/Docs/6. Приложение № 5 Порядок проведения закупки у ЕП.DOCX"
import doc10 from "../Components/Docs/7. Приложение № 6 Особенности проведения закупок в бумажной форме.docx"
import doc11 from "../Components/Docs/8. Приложение № 7 Признание закупки несостоявшейся.docx"
import doc12 from "../Components/Docs/9. Приложение № 8 Особые закупочные ситуации.docx"
import doc13 from "../Components/Docs/10. Приложение № 9 Особенности проведения закупок у субъектов МСП.DOCX"
import doc14 from "../Components/Docs/11. Приложение № 10 Правила  оценки..docx"
import doc15 from "../Components/Docs/12. Приложение № 11 Методика НМЦ.DOCX"

const abouts = [
  {
    id: 1,
    img: history,
    text: (
      <div>
        <p>
          Завод вырос из мастерской по ремонту геодезических приборов,
          организованной в 1928 году в Москве. В 1930 году мастерская была
          передана в ведение Главного Управления государственной съёмки и
          картографии (ГУГСК НКВД) и переименована в завод "Аэрогеоприбор".
        </p>
        <p>
          В конце 1941года завод был эвакуирован в тыл, а в 1942 году переведён
          в г. Красногорск, где было организовано оптико-механическое
          производство.
        </p>
        <p>
          Постановление Совета Министров СССР от 13 апреля 1946 года "О
          мероприятиях по картографированию территории СССР на 1946 -1947гг."
          обязало Главное Управление геодезии и картографии при Совете Министров
          СССР восстановить в городе Москве завод "Аэрогеоприбор" по
          производству высокоточного геодезического и фотограмметрического
          оборудования.
        </p>
        <p>
          Постановлением Коллегии ГУГК при Совете Министров СССР от 8.03.1946
          года завод был размещен в здании Московского аэрогеодезического
          предприятия по адресу : Шелапутинский пер.6.
        </p>
        <p>
          В последствии завод "Аэрогеоприбор" был переименован в ЭОМЗ ЦНИИГАиК;
          ЭОМЗ.
        </p>
        <p>
          До 2012 года завод имеел полное наименование: Федеральное
          государственное унитарное предприятие "Экспериментальный
          оптико-механический завод", сокращенно ЭОМЗ и находился в
          ведомственном подчинении Федеральной службы государственной
          регистрации, кадастра и картографии (РОСРЕЕСТР).
        </p>
        <p>В 2012 года завод преобразован в ОАО «ЭОМЗ».</p>
        <p>
          В августе 2015 года завод переименован в АО "ЭОМЗ", в связи со сменой
          организационно-правовой формы общества.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    img: service,
    text: (
      <div>
        <p>
          "ЭОМЗ" является официально аккредитованным сервис партнером по TPS
        </p>
        <p>
          Ремонт тахеометров, одних из самых интеллектуальных устройств в ряду
          геодезического оборудования, представляет собой непростую задачу. Её
          решение можно доверить только профессионалам. Наиболее выгодным
          решением, которое позволит сэкономить сотни тысяч рублей на покупке
          нового прибора, является ремонт тахеометров с помощью специалистов,
          которые не только обладают необходимыми знаниями и многолетним опытом,
          методиками диагностики и контроля параметров, но и применяют
          соответствующее ремонтное и испытательное оборудование, оригинальные
          комплектующие для замены неисправных деталей. Наша компания выполняет
          ремонт тахеометров любой сложности быстро и качественно. Мы
          осуществляем ремонт тахеометров и спутниковых приемников Leica и
          GeoMax.
        </p>
        <p>
          Несмотря на улучшенную защиту и все методы предосторожности тахеометры
          также как и все приборы выходят из строя. Учитывая стоимость такого
          прибора, ремонт тахеометра и, тем более, профилактическое обслуживание
          будет намного более выгодным решением, чем замена устройства.
        </p>
        <p>
          Ремонт тахеометров, который осуществляет наша компания является
          трудоёмким, сложным и ответственным процессом, в регламент которого
          включены несколько десятков операций от самых простых процедур замены
          платы до дорогостоящих работ по замене корпуса телескопа или
          устранению рысканья. Поскольку современный тахеометр сочетает в себе
          функции дальномера, теодолита и микропроцессора, то и его ремонт
          включает в себя несколько основных позиций: ремонт дальномера и
          телескопа; ремонт теодолитной части прибора; прошивку программного
          обеспечения.
        </p>
        <p>
          Независимо от сложности поломки специалисты сервисного центра проведут
          тщательный анализ и диагностику повреждений и выполнят ремонт
          электронного тахеометра в короткие сроки и с неизменно высоким
          качеством
        </p>
        <p>
          Наш сервисный центр оснащён сертифицированным ремонтным оборудованием
          для ремонта тахеометров. По завершению работ специалисты сервисного
          центра выполнят обязательную проверку рабочих характеристик
          тахеометра, будет произведена его настройка, отладка, тестирование и
          обязательная поверка тахеометра. Мы не рекомендуем владельцам
          дорогостоящих тахеометров осуществлять кустарный ремонт тахеометров,
          поскольку это может привести к окончательной поломке прибора и
          неоправданной потере средств и времени.
        </p>
        <p>
          Наша компания осуществляет не только ремонт тахеометров, но и
          предлагает своим клиентам услуги по юстировке, поверке прибора с
          выдачей свидетельства, а также по диагностике тахеометра, которая
          проводится как плановая проверка или перед началом ремонта. В процессе
          диагностики прибора специалисты осуществляют проверку
          работоспособности всех систем тахеометра с описанием найденных
          неисправностей и выдачей рекомендаций по их устранению. Ремонт
          тахеометра в нашем сервисном центре – это выгодный способ продлить
          срок службы хорошему прибору.
        </p>
        <p>
          Авторизованный сервисный центр выполняет ремонт и техническое
          обслуживание тахеометров и спутниковых приемников.
        </p>
        <p>
          Тахеометры GeoMax серии ZOOM 20/30/35 Pro и спутниковые приемники
          Zenith25 ProДополнительная установка арктической версии Polar (до
          -35°С)
        </p>
        <p>
          Тахеометры: Leica серии TCR1200 и FlexLine TS06/09Дополнительная
          установка арктической версии SuperArctic (до-40°С)Спутниковые
          приемники GS08/10/14
        </p>
        <p>
          Предоставляем услуги по климатическим испытаниям приборной продукции.
        </p>
        <p>
          Технические характеристики климатической камеры: рабочий объем:
          2300х2000х1900мм. диапазон стабилизируемых температур: от -40 до +85°С
          точность поддержания температуры: ±1°С
        </p>
        <p>Гарантийное обслуживание всех приборов 1 год.</p>
        <p>тел: 8 (495) 911-14-63эл. почта: eomz-geo@yandex.ru</p>
      </div>
    ),
  },
  {
    id: 3,
    img: documents,
    text: "",
    block: (
      <>
        <a
          href={doc1}
          download='Устав АО Роскартография'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>Устав АО «Роскартография»</p>
          </div>
        </a>
        <a
          href={doc2}
          download='Положение о филиале ЭОМЗ'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>Положение о филиале ЭОМЗ</p>
          </div>
        </a>
      </>
    ),
  },
  {
    id: 4,
    img: certificats,
    text: "",
    certificats: (
      <>
        <NavLink>
          <div>
            <img src={cert1} />
            <p>Сертификат Basis Software</p>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <img src={cert2} />
            <p>Сертификат GEOMAX</p>
          </div>
        </NavLink>
        <NavLink>
          <div>
            <img src={cert3} />
            <p>Сертификат на стереомонитор</p>
          </div>
        </NavLink>
      </>
    ),
  },
  {
    id: 5,
    img: jobs,
  },
  {
    id: 6,
    img: goods,
    text: "",
    block: (
      <>
        <a
          href={doc3}
          download='Положение о закупке'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>1. Положение о закупке</p>
          </div>
        </a>
        <a
          href={doc4}
          download='Приложение № 1 Положение о закупочной комиссии'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>2. Приложение № 1 Положение о закупочной комиссии</p>
          </div>
        </a>
        <a
          href={doc5}
          download='Приложение № 2 Положение о планировании'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>3. Приложение № 2 Положение о планировании</p>
          </div>
        </a>
        <a
          href={doc6}
          download='Приложение № 1_2021'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>3.1. Приложение № 1_2021</p>
          </div>
        </a>
        <a
          href={doc7}
          download='Приложение № 3 Порядок проведения торгов'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>4. Приложение № 3 Порядок проведения торгов</p>
          </div>
        </a>
        <a
          href={doc8}
          download='Приложение № 4 Особенности выбора нескольких победителей'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>5. Приложение № 4 Особенности выбора нескольких победителей</p>
          </div>
        </a>
        <a
          href={doc9}
          download='Приложение № 5 Порядок проведения закупки у ЕП'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>6. Приложение № 5 Порядок проведения закупки у ЕП</p>
          </div>
        </a>
        <a
          href={doc10}
          download='Приложение № 6 Особенности проведения закупок в бумажной форме'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>
              7. Приложение № 6 Особенности проведения закупок в бумажной форме
            </p>
          </div>
        </a>
        <a
          href={doc11}
          download='Приложение № 7 Признание закупки несостоявшейся'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>8. Приложение № 7 Признание закупки несостоявшейся</p>
          </div>
        </a>
        <a
          href={doc12}
          download='Приложение № 8 Особые закупочные ситуации'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>9. Приложение № 8 Особые закупочные ситуации</p>
          </div>
        </a>
        <a
          href={doc13}
          download='Приложение № 9 Особенности проведения закупок у субъектов МСП'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>
              10. Приложение № 9 Особенности проведения закупок у субъектов МСП
            </p>
          </div>
        </a>
        <a
          href={doc14}
          download='Приложение № 10 Правила оценки'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>11. Приложение № 10 Правила оценки</p>
          </div>
        </a>
        <a
          href={doc15}
          download='Приложение № 11 Методика НМЦ'
          target='_blank'
        >
          <div>
            <img src={doc} />
            <p>12. Приложение № 11 Методика НМЦ</p>
          </div>
        </a>
      </>
    ),
  },
]

export default abouts
