import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps"
import styles from "./Contacts.module.css"
import line from "../Images/Services/line.png"

function Contacts() {
  return (
    <div className={styles.wrapper}>
      <h1>Контакты</h1>
      <img
        src={line}
        className={styles.line}
      />
      <div className={styles.text}>
        <h5>
          Филиал АО «Роскартографии» "Экспериментальный оптико-механический
          завод"
        </h5>
        <p>109004, г. Москва, Шелапутинский пер. д.6 стр.3</p>
        <p>
          Тел./Факс: +7 (495) 911-01-12, +7 (495) 911-02-75, +7 (495) 911-32-70
        </p>
        <p>e-mail: oaoeomz@gmail.com</p>
        <p>Аренда офисных помещений тел.: +7 (495) 911-02-75</p>
      </div>
      <YMaps>
        <Map
          defaultState={{
            center: [55.748077, 37.662305],
            zoom: 16,
          }}
          width='100%'
          // className={styles.map}
        >
          <Placemark defaultGeometry={[55.748077, 37.662305]} />
        </Map>
      </YMaps>
    </div>
  )
}

export default Contacts
