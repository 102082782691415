const articles = [
  {
    title:
      "Е.В.Кравцова (АО «Роскартография»). Доклад «Цифровые геопространственные продукты АО «Роскартография» (Сеул, 2019) ",
    type: "Статья • 28.10.2019",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/roskartografiya_kravczovaev-8eb26167.pdf",
  },
  {
    title:
      "И.А.Аникеева (АО «Роскартография»). Доклад «Оценка уровня сигнал/шум аэро- и космических изображений» (Сеул, 2019) ",
    type: "Статья • 28.10.2019",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/rakurs-2019-anikeeva-a9166bb5.pdf",
  },
  {
    title:
      "В.А.Спиренков (Росреестр), С.Н.Карутин (АО «Роскартография»), А.В.Ребрий (ФГБУ «Центр геодезии, карторграфии и ИПД»), А.Е.Прохоренко (АО «Роскартография»). Цифровая трансформация технологий создания пространственных данных ",
    type: "Статья • 28.09.2019",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/czifrovaya-transformacziya-prav-33_rkg3-6311667d.pdf",
  },
  {
    title:
      "Газета «Комсомольская правда — Иркутск». От Красноярска до Сахалина – география работ иркутских геодезистов ",
    type: "Статья • 28.03.2019",
    link: "https://www.irk.kp.ru/daily/26951/4004486/",
  },
  {
    title:
      "Журнал «Геопрофи» Д.М.Хайдукова (АО «Уралгеоинформ»), И.Н.Насибутдинов (АО «Уралгеоинформ»), Я.В.Лерман (АО «Уралгеоинформ»), П.А.Анашкин (АО «Уралгеоинформ»), И.Г.Емельянов (НПК «Фотоника»), Ю.Г.Райзман (Phase One Industrial). Опыт использования аэросъемочного комплекса Phase One 190mp в России ",
    type: "Статья • 28.02.2019",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/02_2019-uralgeoinform-2e18d0d2.pdf",
  },
  {
    title:
      "Журнал «Геопрофи» Н.К.Шендрик (Сибирский государственный университет геосистем и технологий, Новосибирск), П.К.Шитиков (АО «ПО Инжгеодезия»). Опыт определения положения пунктов сети ПДБС Новосибирской области в ГСК–2011 ",
    type: "Статья • 28.06.2018",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/06_2018-inzhgeodeziya-506b1a35.pdf",
  },
  {
    title:
      "Журнал «Геопрофи» Д.М.Красников (АО «Роскартография»). Электронный Национальный Атлас Арктики ",
    type: "Статья • 28.05.2018",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/05_2018-naczionalnyj-atlas-arktiki-2835bf0c.pdf",
  },
  {
    title:
      "Журнал «Геопрофи» Т.В.Ефимов (АО «Новгород АГП»). Картографический магазин «Глобус» Новгородского аэрогеодезического предприятия ",
    type: "Статья • 20.05.2018",
    link: "https://roscartography.ru/upload/dynamic/2022-04/27/05_2018-novgorod-agp-d651c196.pdf",
  },
]

export default articles
