import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { CategoryContext } from "../Context/CategoryContext"
import styles from "./News.module.css"
import news from "../Data/news"
import articles from "../Data/articles"
import photos from "../Data/photos"
import videos from "../Data/videos"
import presentations from "../Data/presentations"
import znews from "../Data/z-news"

function News() {
  const [info, setInfo] = useState(news)

  const { activity, changeActivity } = useContext(CategoryContext)

  useEffect(() => {
    setInfo(news[activity])
  }, [activity])

  const dataNews = news.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  const dataArticles = articles.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  const dataPhotos = photos.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  const dataVideos = videos.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  const dataPresentations = presentations.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  const dataZnews = znews.map((el) => {
    return (
      <NavLink
        to={el.link}
        target='_blank'
      >
        <div className={styles.block}>
          <div className={styles.text}>
            <h5>{el.title}</h5>
            <p>{el.type}</p>
          </div>
          <img src={el.img} />
        </div>
      </NavLink>
    )
  })

  return (
    <div className={styles.wrapper}>
      <h1>Новости</h1>
      <nav>
        <button
          className={
            activity === 0 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(0)}
        >
          Лента
        </button>
        <button
          className={
            activity === 1 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(1)}
        >
          Новости
        </button>
        <button
          className={
            activity === 2 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(2)}
        >
          Статьи
        </button>
        <button
          className={
            activity === 3 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(3)}
        >
          Фото
        </button>
        <button
          className={
            activity === 4 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(4)}
        >
          Видео
        </button>
        <button
          className={
            activity === 5 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(5)}
        >
          Презентации
        </button>
        <button
          className={
            activity === 6 ? (styles.link, styles.active) : styles.link
          }
          onClick={() => changeActivity(6)}
        >
          Z-новости
        </button>
      </nav>
      <div className={styles.new}>{activity === 0 && dataNews}</div>
      <div className={styles.new}>{activity === 1 && dataNews}</div>
      <div className={styles.new}>{activity === 2 && dataArticles}</div>
      <div className={styles.new}>{activity === 3 && dataPhotos}</div>
      <div className={styles.new}>{activity === 4 && dataVideos}</div>
      <div className={styles.new}>{activity === 5 && dataPresentations}</div>
      <div className={styles.new}>{activity === 6 && dataZnews}</div>
    </div>
  )
}

export default News
