import { NavLink } from "react-router-dom"
import styles from "./Subsidiaries.module.css"

function Subsidiaries() {
  return (
    <div className={styles.wrapper}>
      <h1>Дочерние общества АО «Роскартография»</h1>
      <div className={styles.card}>
        <h5>
          Филиал «Научно-исследовательский и производственный центр «Природа»
          (Филиал «Центр «Природа») АО «Роскартография»
        </h5>
        <p>Адрес: 111394, г. Москва, Ул. Полимерная, д. 10</p>
        <p>Исполнительный директор: Слигун Наталья Анатольевна</p>
        <p>Телефон: +7 (495) 302-85-34</p>
        <p>Факс: +7 (495) 301-42-85</p>
        <p>E-mail: admin@priroda-center.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.priroda-center.ru/'
            target='_blank'
          >
            www.priroda-center.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ЦФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Экспериментальный оптико-механический завод» (филиал «ЭОМЗ»)
          АО «Роскартография»
        </h5>
        <p>Адрес: 109004, г. Москва, пер. Шелапутинский, д. 6, стр. 3</p>
        <p>Исполнительный директор: Платонов Сергей Николаевич</p>
        <p>Телефон: +7 (495) 911-32-70 Факс: +7 (495) 911-02-75</p>
        <p>E-mail: oaoeomz@gmail.com</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://eomz.ru/'
            target='_blank'
          >
            eomz.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ЦФО</p>
        </div>
      </div>
      <div
        className={styles.card}
        id='special'
      >
        <h5>Филиал «Аэрогеодезия»</h5>
        <p>
          Адрес: 192102, г. Санкт-Петербург, ул. Бухарестская, д. 8, Литера А
        </p>
        <p>Исполнительный директор: Егоров Александр Владимирович</p>
        <p>Телефон: +7 (812) 766-29-79 Факс: +7 (812) 766-56-41</p>
        <p>E-mail: aero@agspb.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://www.agspb.ru/'
            target='_blank'
          >
            www.agspb.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СЗФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Приморское аэрогеодезическое предприятие (филиал «ПриморАГП)
          АО «Роскартография»
        </h5>
        <p>
          Адрес: 692760, Приморский край, г. Артем, ул. 40 лет Октября, д. 40
        </p>
        <p>Исполнительный директор: Дембицкий Леонид Владимирович</p>
        <p>Телефон: +7 (423) 263-35-47</p>
        <p>E-mail: mail@primoragp.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://primoragp.ru/'
            target='_blank'
          >
            primoragp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ДФО</p>
        </div>
      </div>
      <div
        className={styles.card}
        id='special'
      >
        <h5>
          Филиал «Восточно-Сибирское аэрогеодезическое предприятие (филиал
          «ВостСибАГП») АО «Роскартография»
        </h5>
        <p>
          Адрес: 664007, Иркутская область, г. Иркутск, Ул. Нижняя Набережная,
          д. 14
        </p>
        <p>Исполнительный директор: Мазуров Сергей Федорович</p>
        <p>Телефон: +7 (395) 224-37-97 Факс: +7 (395) 224-38-78</p>
        <p>E-mail: vsagp@vsagp.com</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://www.vsagp.ru/'
            target='_blank'
          >
            vsagp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>Филиал «Уралгеоинформ» (филиал «УГИ») АО «Роскартография»</h5>
        <p>
          Адрес: 620078, Свердловская область, г. Екатеринбург, ул.
          Студенческая, стр. 51
        </p>
        <p>Исполнительный директор: Анашкин Павел Анатольевич</p>
        <p>Тел/факс: +7 (343) 374-80-03</p>
        <p>E-mail: ugi@ugi.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://ugi.ru/'
            target='_blank'
          >
            ugi.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>УрФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Красноярское аэрогеодезическое предприятие» (филиал «КАГП») АО
          «Роскартография»
        </h5>
        <p>
          Адрес: 660056, Красноярский край, г. Красноярск, Ул. Иртышская, д. 2А
        </p>
        <p>Исполнительный директор: Гаврюшов Юрий Владимирович</p>
        <p>Телефон: +7 (391) 201-83-23</p>
        <p>E-mail: kagp24@mail.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://kagp24.ru/'
            target='_blank'
          >
            kagp24.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Красноярский центр «Природа» (филиал «КЦ Природа») АО
          «Роскартография»
        </h5>
        <p>
          Адрес: 660049, Красноярский край, г. Красноярск, Ул. Ленина, д. 41
        </p>
        <p>Исполнительный директор: Варфоломеев Дмитрий Викторович</p>
        <p>Телефон: +7 (391) 325-11-66</p>
        <p>E-mail: goscenter.pr@mail.ru</p>
        <p>Сайт: отсутствует</p>
        <div className={styles.district}>
          <p>СФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Балтийское аэрогеодезическое предприятие (филиал «БалтАГП») АО
          «Роскартография»
        </h5>
        <p>
          Адрес: 236010, Калининградская область, г. Калининград, Пр-кт Победы,
          Д. 161
        </p>
        <p>Исполнительный директор: Дробиз Михаил Валерьевич</p>
        <p>Тел/факс: +7 (401) 233-21-41</p>
        <p>E-mail: info@baltagp.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://baltagp.ru/'
            target='_blank'
          >
            baltagp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СЗФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Верхневолжское аэрогеодезическое предприятие (филиал «ВАГП»)
          АО «Роскартография»
        </h5>
        <p>
          Адрес: 603122, Нижегородская область, г. Нижний Новгород, Ул. Ванеева,
          д. 205, Помещ. 50
        </p>
        <p>Исполнительный директор: Штерн Сергей Леонидович</p>
        <p>Тел/факс: +7 (831) 417-65-21</p>
        <p>E-mail: vagp@mts-nn.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://www.vagp-nn.ru/'
            target='_blank'
          >
            www.vagp-nn.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ПФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Производственное объединение Инжгеодезия» (филиал «ПО
          Инжгеодезия») АО «Роскартография»
        </h5>
        <p>
          Адрес: 630132, Новосибирская область, г. Новосибирск, ул. Челюскинцев,
          д. 50
        </p>
        <p>Исполнительный директор: Чухвачева Юлия Евгеньевна</p>
        <p>Телефон: +7 (383) 363-88-00</p>
        <p>E-mail: info@geonsk.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='https://geonsk.ru/'
            target='_blank'
          >
            geonsk.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Омская картографическая фабрика» (филиал «ОКФ») АО
          «Роскартография»
        </h5>
        <p>Адрес: 644070, Омская область, г. Омск, Ул. Куйбышева, д. 32</p>
        <p>Исполнительный директор: Игонин Александр Иванович</p>
        <p>Телефон: +7 (381) 266-52-06 — приемная</p>
        <p>Факс: +7 (381) 266-51-72 — коммерческий отдел</p>
        <p>E-mail: okf55okf@mail.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.okf55.ru/'
            target='_blank'
          >
            www.okf55.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Северо-Кавказское аэрогеодезическое предприятие» (филиал
          «СевКавАГП») АО «Роскартография»
        </h5>
        <p>
          Адрес: 357502, Ставропольский край, г. Пятигорск, пр-кт Горького, д. 4
        </p>
        <p>Исполнительный директор: Погорелов Вячеслав Викторович</p>
        <p>Телефон: +7 (879) 336-37-50</p>
        <p>Факс: +7 (879) 397-37-86</p>
        <p>E-mail: skagp@bk.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.skagp.ru/'
            target='_blank'
          >
            www.skagp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>СКФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Средневолжское аэрогеодезическое предприятие (филиал «СВАГП»)
          АО «Роскартография»
        </h5>
        <p>
          Адрес: 443016, Самарская область, г. Самара, ул. Черемшанская, д. 89
        </p>
        <p>Исполнительный директор: Костин Михаил Александрович</p>
        <p>Телефон: +7 (846) 951-92-77</p>
        <p>Факс: +7 (846) 951-90-33</p>
        <p>E-mail: svagp@samtel.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.svagp.ru/ru/'
            target='_blank'
          >
            www.svagp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ПФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Дальневосточное аэрогеодезическое предприятие» (филиал ДВАГП»)
          АО «Роскартография»
        </h5>
        <p>
          Адрес: 680000, Хабаровский край, г. Хабаровск, Ул. Шеронова, д. 97
        </p>
        <p>Исполнительный директор: Сивкова Марина Михайловна</p>
        <p>Тел/факс: +7 (421) 230-43-19</p>
        <p>E-mail: dvagp@dvagp.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.dvagp.ru/'
            target='_blank'
          >
            www.dvagp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ДФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>Филиал «Уралмаркшейдерия» (филиал «УМШ») АО «Роскартография»</h5>
        <p>
          Адрес: 454138, Челябинская область, г. Челябинск, ул. Порядковая 1-я,
          д. 10
        </p>
        <p>Исполнительный директор: Андрющенко Андрей Викторович</p>
        <p>Телефон: +7 (351) 211-44-32</p>
        <p>E-mail: info@utmp.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://www.utmp.ru/'
            target='_blank'
          >
            www.utmp.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>УРФО</p>
        </div>
      </div>
      <div className={styles.card}>
        <h5>
          Филиал «Якутское аэрогеодезическое предприятие (филиал «ЯкутАГП») АО
          «Роскартография»
        </h5>
        <p>Адрес: 677980, Якутск, ул. Короленко, 2</p>
        <p>Генеральный директор: Сивкова Марина Михайловна</p>
        <p>Телефон: +7 (411) 242-00-06, +7 (411) 242-29-56</p>
        <p>E-mail: yakutagp@list.ru</p>
        <p>
          Сайт:{" "}
          <NavLink
            to='http://agp14.ru/'
            target='_blank'
          >
            agp14.ru
          </NavLink>
        </p>
        <div className={styles.district}>
          <p>ДФО</p>
        </div>
      </div>
    </div>
  )
}

export default Subsidiaries
